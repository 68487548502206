import * as api from '../api';

// Action Creators
// 
export const getFullSearchList = () => async (dispatch) => {
  try {
    const { data } = await api.getFullSearchList();
    dispatch({ type: 'GET_FULL_SEARCH_LIST', payload: data });
  } catch (error) {
    console.log(error.message)
  }
}

// Create an action to filter stock data based on a partial ticker symbol or company name
export const getFilterStockData = (searchTerm) => async (dispatch) => {
  try {
    const { data } = await api.filterStockData(searchTerm);
    dispatch({ type: 'FILTER_SEARCH_LIST', payload: data });
  } catch (error) {
    console.log(error.message)
  }
}

export const getRecentSearchList = () => async (dispatch) => {
  try {
    const { data } = await api.getRecentSearchList();
    if(data.length){
      dispatch({ type: 'GET_RECENT_SEARCH_LIST', payload: data });
      dispatch({ type: 'SET_PRICE_CHART_SYMBOL', payload: data[0] });
    }
    dispatch({ type: 'FINIHSED_FETCH_RECENT' });
  } catch (error) {
    console.log(error.message)
  }
}

export const addToRecentSearchList = (ticker) => async (dispatch) => {
  try {
    dispatch({ type: 'ADD_TO_RECENT_SEARCH_LIST', payload: ticker });
    await api.addToRecentSearchList(ticker);
  } catch (error) {
    console.log(error.message)
  }
}

export const compareSearch = (ticker) => async (dispatch) => {
  try {
    dispatch({ type: 'COMPARE_SEARCH', payload: ticker });
    // await api.addToRecentSearchList(ticker);
  } catch (error) {
    console.log(error.message)
  }
}