// External imports
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import styled, { useTheme } from 'styled-components'
import tw from 'twin.macro'
import html2canvas from 'html2canvas';
import _ from 'lodash'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { useMediaQuery } from 'react-responsive'
import { MdOutlineFitScreen, MdOutlineShowChart  } from 'react-icons/md'
import { HiOutlineShare, HiOutlineDocumentDuplicate  } from 'react-icons/hi'
import { TbChartCandle } from "react-icons/tb";

// Internal imports
import {
  Modal,
  ContentContainer,
  PriceGraph,
  TextOptionSelector,
  SingleSelectDropdown,
  ShareModal,
  IconButtons,
  BoxAnimation,
} from '../../../components'
import { getNameConversion } from '../../../utils'
import { updatePriceChartTimeframe } from '../../../actions/userPreferences'
import { useGetPriceChartDataQuery } from '../../../rtk'

dayjs.extend(utc)
dayjs.extend(timezone)

// Styling
const ChartDiv = styled.div`
  ${tw`w-full h-[250px] flex flex-col text-sm`};
`
const borderColourSelector = (data) => {
  if (data.length >= 2 && data[0].close <= data[data.length - 1].close)
    return '#8b2126'
  return '#218B82'
}

const priceToggle = [
  { name: '1D', labels: 'HH:mm', interval: '1min', useLiveData: true },
  { name: '5D', labels: 'DD MMM', interval: '15min', useLiveData: true },
  { name: '1M', labels: 'DD MMM', interval: '30min', useLiveData: true },
  { name: '6M', labels: 'MMM YY', interval: '1day', useLiveData: true },
  { name: '1Y', labels: 'MMM YY', interval: '1day', useLiveData: true },
  // {name:"YTD", labels:"MMM", interval:null, useLiveData: true },
  { name: '5Y', labels: 'YYYY', interval: '1day', useLiveData: true },
  { name: '10Y', labels: 'YYYY', interval: '1day', useLiveData: true },
  { name: 'MAX', labels: 'YYYY', interval: '1day', useLiveData: true },
];

const getProperDay = (est) => {
  if (est.day() === 0) return est.subtract(2, 'days')
  else if (est.day() === 1) return est.subtract(3, 'days')
  else return est.subtract(1, 'days')
}

const getBackDate = (timeframe) => {
  const localTime = dayjs()
  const est = localTime.tz('America/New_York')
  switch (timeframe) {
    case '1D':
      return getProperDay(est)
    case '5D':
      return est.subtract(5, 'days')
    case '1M':
      return est.subtract(1, 'months')
    case '6M':
      return est.subtract(6, 'months')
    case 'YTD':
      return est.startOf('year')
    case '1Y':
      return est.subtract(1, 'years')
    case '5Y':
      return est.subtract(5, 'years')
    case '10Y':
      return est.subtract(10, 'years')
    default:
      return dayjs(new Date(1950, 1, 1))
  }
}

// Section containing valuation for the searched stock
export const Valuation = ({ symbol, priceChartTimeframe, ticker }) => {
  const palette = useTheme()
  const isTablet = useMediaQuery({ maxWidth: '1100px' })
  const showDropDownToggles = useMediaQuery({ maxWidth: '650px' })
  const [openShareModal, setOpenShareModal] = useState(false)
  const [showCandleChart, setShowCandleChart] = useState(true);
  const [openModal, setOpenModal] = useState(false)
  const chartRef = useRef(null);
  const [chartImage, setChartImage] = useState('');
  const statType = 'price'
  const dispatch = useDispatch()

  // Data for price movements
  const priceChange = [
    {
      name: '1D: ',
      change: `${
        ((ticker?.dailyStockPrice[0].close - ticker?.dailyStockPrice[1].close) / ticker?.dailyStockPrice[1].close) * 100
      }`,
      changeSuffix: '%',
    },
    { name: '5D: ', change: `${ticker?.priceChanges?.fiveDayPricePercentageChange}`, changeSuffix: '%' },
    { name: '1M: ', change: `${ticker?.priceChanges?.oneMonthPricePercentageChange}`, changeSuffix: '%' },
    { name: '6M: ', change: `${ticker?.priceChanges?.sixMonthPricePercentageChange}`, changeSuffix: '%' },
    { name: '1Y: ', change: `${ticker?.priceChanges?.oneYearPricePercentageChange}`, changeSuffix: '%' },
    { name: '5Y: ', change: `${ticker?.priceChanges?.fiveYearPricePercentageChange}`, changeSuffix: '%' },
    { name: '10Y: ', change: `${ticker?.priceChanges?.tenYearPricePercentageChange}`, changeSuffix: '%' },
  ];

  // Disabling scrolling when modal is open
  useEffect(() => {
    document.body.style.overflow = openModal ? 'hidden' : 'auto'
  }, [openModal])

  const filteredPriceToggle = priceToggle.find(
    (toggle) => toggle.name === priceChartTimeframe
  )

  const { data, isFetching } = useGetPriceChartDataQuery({
    symbol: symbol,
    interval: filteredPriceToggle?.interval,
    fromDate: getBackDate(filteredPriceToggle?.name)?.format('YYYY-MM-DD'),
    toDate: dayjs().format('YYYY-MM-DD'),
    timeframe: filteredPriceToggle?.name,
  })

  const captureChart = () => {
    html2canvas(chartRef.current).then((canvas) => {
      setChartImage(canvas.toDataURL('image/png'));
    });
  }

  // Individual chart component
  const Chart = ({ width, height }) => (
    <ChartDiv style={{ width, height }} className="cursor-auto" ref={chartRef}>
      {data?.chartData && !isFetching ? (
        <PriceGraph
          priceChartTimeframe={priceChartTimeframe}
          chartData={{
            priceData: data.chartData.map((data) => data.close).reverse(),
            volumeData: data.chartData.map((data) => data.volume).reverse(),
            labels: data.chartData.map((data) => data.date).reverse(),
            candlestickData: data.chartData.map((data) => ({
              open: data.open,
              high: data.high,
              low: data.low,
              close: data.close,
            })).reverse(),
          }}
          chartColour={borderColourSelector(data.chartData)}
          chartFillColour={palette.colors.priceChartFill}
          labelDisplay={
            priceToggle.find((price) => price.name === priceChartTimeframe).labels
          }
          showCandleChart={showCandleChart}
        />
      ) : null}
      {isFetching && (
        <div className="flex justify-center items-center h-full">
          <BoxAnimation/>
        </div>
      )}
    </ChartDiv>
  )

  const updatePriceChart = (value) => {
    dispatch(updatePriceChartTimeframe(value)).then(() => {
      // getData(value)
    })
  }

  return (
    <div className="flex flex-row space-x-6 w-full max-w-[2500px] h-full items-start">
      <ContentContainer
        Header={getNameConversion(statType)}
        Icons={
          <div className="flex flex-row space-x-1">
            {/* <IconButtons
              action={() => {
                setOpenShareModal(true)
                captureChart()
              }}
              icon={<HiOutlineShare />}
              text={'Share'}
            />
            <ShareModal
              open={openShareModal}
              close={() => {
                setOpenShareModal()
              }}
              icon={<HiOutlineShare />}
              header="Share"
              content={chartImage}
            /> */}
            {/* <IconButtons
              action={() => setShowCandleChart(prevState => !prevState)}
              icon={showCandleChart ? <TbChartCandle /> : <MdOutlineShowChart />}
              text={showCandleChart ? 'Candlestick' : 'Line graph'}
            /> */}
            {/* <IconButtons
              action={() => setOpenModal(true)}
              icon={<MdOutlineFitScreen />}
              text={'Expand'}
            /> */}
            {showDropDownToggles && (
              <SingleSelectDropdown
                setSelected={updatePriceChart}
                dropDownOptions={priceToggle.map((price) => price.name)}
                width={'70px'}
                useParentInput={true}
                parentInput={priceChartTimeframe}
              />
            )}
          </div>
        }
        Toggle={
          !showDropDownToggles && (
            <TextOptionSelector
              fit={true}
              options={priceToggle.map((price) => price.name)}
              selection={priceChartTimeframe}
              setSelection={updatePriceChart}
              isDispatchable={false}
              priceChange={isTablet ? null : priceChange}
            />
          )
        }
        Width={'fit-content'}
        Content={
          <>
            <ChartDiv
              style={{ width: '1000', height: '240' }}
              className="cursor-auto"
              ref={chartRef}
            >
              {data?.chartData && !isFetching ? (
                <PriceGraph
                  priceChartTimeframe={priceChartTimeframe}
                  chartData={{
                    priceData: data.chartData.map((data) => data.close).reverse(),
                    volumeData: data.chartData.map((data) => data.volume).reverse(),
                    labels: data.chartData.map((data) => data.date).reverse(),
                    candlestickData: data.chartData.map((data) => ({
                      open: data.open,
                      high: data.high,
                      low: data.low,
                      close: data.close,
                    })).reverse(),
                  }}
                  chartColour={borderColourSelector(data.chartData)}
                  chartFillColour={palette.colors.priceChartFill}
                  labelDisplay={
                    priceToggle.find((price) => price.name === priceChartTimeframe).labels
                  }
                  showCandleChart={showCandleChart}
                />
              ) : null}
              {isFetching && (
                <div className="flex justify-center items-center h-full">
                  <BoxAnimation/>
                </div>
              )}
            </ChartDiv>
          </>
        }
      />
      <Modal
        open={openModal}
        maxWidth={'2600px'}
        closeModal={() => setOpenModal(false)}
        headerContent={<>{_.capitalize(statType)}</>}
        content={<Chart height={600} />}
      />
    </div>
  )
}
