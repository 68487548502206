// External imports
import React, { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import tw from 'twin.macro'
import { AnimatePresence, motion } from 'framer-motion'
import { IoClose, IoSearch } from 'react-icons/io5'

// Internal imports
import { IconButtons, Company } from '../../components/index'
import { useDebounce, useClickOutside } from '../../hooks'
import { compareSearch } from '../../actions/searchLists'
import { PlanRestrictionModal } from '../modals/planRestrictionModal'

// Styling
const SearchSection = styled.div`${tw`relative w-full max-w-[2500px] flex flex-col justify-center items-center text-textBase rounded`};`
const SearchBarContainer = styled(motion.div)`${tw`w-full flex flex-row rounded bg-neutralBg px-2`};`
const SearchInputContainer = styled.div`${tw`w-full min-h-[50px] flex items-center`};`
const SearchInput = styled.input`
  ${tw`w-full h-full outline-none border-none text-sm  bg-[transparent]`};
  &::placeholder {
    transition: all 500ms ease-in-out;
    color: var(--textMuted);
  }
  &:hover&::placeholder {
    color: var(--textBase);
  }
`
const SearchIcon = styled.span`${tw`text-lg mr-2.5 w-[35px] h-[35px] flex justify-center items-center rounded-full text-textBase `};`
const CloseIcon = styled(motion.span)`
  ${tw`text-xl align-middle cursor-pointer text-textMuted hover:text-textBase`};
  transition: all 200ms ease-in-out;
`
const LoadingWrapper = styled.div`${tw`w-full h-[55px] flex items-center justify-center py-4`};`
const WarningMessage = styled.span`${tw`text-textMuted text-sm flex self-center justify-self-center`};`

export const SearchBar2 = ({ onSearchClick, close, dropdownLimit }) => {
  const dispatch = useDispatch()
  const inputRef = useRef()
  const navigate = useNavigate()

  const { hasActivePremiumSubscription } = useSelector((state) => state.watchListReducer)
  const { recentSearchList, fullSearchList } = useSelector((state) => state.searchReducer)
  const { searchedStockData } = useSelector((state) => state.stockReducer)

  const defaultPlaceholderText = 'Search stocks to compare...'
  const [isOpen, setIsOpen] = useState(false)
  const [noCompanies, setNoCompanies] = useState(false)
  const [placeholderText, setPlaceholderText] = useState('Search stocks to compare...')
  const [showPlanRestriction, setShowPlanRestriction] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredCompanies, setFilteredCompanies] = useState([])

  const changeHandler = (e) => {
    e.preventDefault()
    if (hasActivePremiumSubscription || recentSearchList.length < 5) {
      if (e.target.value.trim() === '') {
        setIsLoading(true)
        setNoCompanies(false)
        setSearchQuery('')
        setFilteredCompanies([])
        setPlaceholderText(defaultPlaceholderText)
        if (inputRef.current) inputRef.current.value = ''
        setIsOpen(false)
      } else {
        setNoCompanies(false)
        setSearchQuery(e.target.value)
      }
    } else {
      if (recentSearchList.length >= 5) {
        setShowPlanRestriction(true)
      }
    }
  }

  const collapseContainer = () => {
    setIsLoading(true)
    setTimeout(() => {
      setIsOpen(false)
      setSearchQuery('')
      setFilteredCompanies([])
      setNoCompanies(false)
      setPlaceholderText(defaultPlaceholderText)
      if (inputRef.current) inputRef.current.value = ''
    }, 10)
  }

  const searchCompanies = async () => {
    setIsLoading(true)
    if (!searchQuery || searchQuery.trim() === '') return

    const sortedList = fullSearchList.sort((a, b) =>
      a.symbol.localeCompare(b.symbol)
    )

    const tickerMatches = sortedList.filter((company) =>
      company.symbol.toUpperCase().startsWith(searchQuery.toUpperCase())
    )

    const sortedByNameList = fullSearchList.sort((a, b) => {
      const companyNameA = a?.companyName?.toUpperCase() || ''
      const companyNameB = b?.companyName?.toUpperCase() || ''
      return companyNameA.localeCompare(companyNameB)
    })

    const nameStartsWithMatches = sortedByNameList.filter((company) =>
      company?.companyName?.toUpperCase().startsWith(searchQuery.toUpperCase())
    )

    const remainingNameMatches = sortedByNameList
      .filter((company) => !nameStartsWithMatches.includes(company))
      .filter((company) =>
        company?.companyName?.toUpperCase().includes(searchQuery.toUpperCase())
      )

    // Combine the results
    const uniqueCompanies = new Set([
      ...tickerMatches,
      ...nameStartsWithMatches,
      ...remainingNameMatches,
    ])

    const filteredList = Array.from(uniqueCompanies).slice(0, 15)

    setFilteredCompanies(filteredList)

    if (filteredList.length === 0) {
      setNoCompanies(true)
    } else {
      setNoCompanies(false)
      setFilteredCompanies(filteredList.slice(0, dropdownLimit))
    }

    setIsLoading(false)
  }

  useDebounce(searchQuery, 500, searchCompanies)

  return (
    <div className="relative max-w-[2500px] w-full">
      <SearchSection
        ref={useClickOutside(collapseContainer)}
        className="relative"
      >
        <SearchBarContainer>
          <SearchInputContainer>
            <div>
              <SearchIcon>
                <IoSearch />
              </SearchIcon>
            </div>
            <SearchInput
              placeholder={placeholderText}
              onFocus={() => {
                setPlaceholderText('Type the name or ticker to search...')
              }}
              onBlur={() => {
                setPlaceholderText(defaultPlaceholderText)
              }}
              onClick={onSearchClick}
              onInput={() => setIsOpen(true)}
              ref={inputRef}
              value={searchQuery}
              onChange={changeHandler}
            />
            <AnimatePresence>
              {isOpen && (
                <CloseIcon
                  key="close-icon"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  onClick={collapseContainer}
                  transition={{ duration: 0.2 }}
                >
                  <IconButtons
                    action={() => collapseContainer()}
                    icon={<IoClose />}
                    text={'Close'}
                  />
                </CloseIcon>
              )}
            </AnimatePresence>
          </SearchInputContainer>
        </SearchBarContainer>
        <div className='w-full'>
            <div className='text-textMuted text-sm pt-2'>Reference</div>
            <Company 
              logo={searchedStockData?.profile?.logo}
              ticker={searchedStockData.symbol}
              name={searchedStockData?.profile?.companyName}
              exchange={searchedStockData?.profile?.exchangeShortName}
            />
          </div>
          {isOpen && filteredCompanies.length > 0 && 
            <div className='w-full'>
              <span className='text-textMuted text-sm'>Compare to</span>
              <div className='divide-y-1 divide-neutralBg'>
                {noCompanies && (
                  <LoadingWrapper>
                    <WarningMessage>No stocks found</WarningMessage>
                  </LoadingWrapper>
                )}
                {filteredCompanies.map((item) => (
                  <Company
                    key={item['_id']}
                    logo={item.logo}
                    ticker={item.symbol}
                    name={item.companyName}
                    exchange={item.exchangeShortName}
                    onClick={() => {
                      collapseContainer()
                      dispatch(compareSearch(item.symbol))
                      close()
                    }}
                  />
                ))}
              </div>
            </div>
          }
        <PlanRestrictionModal
          open={showPlanRestriction}
          close={() => {
            setShowPlanRestriction()
          }}
        />
      </SearchSection>
    </div>
  )
}