import initialState from "../state";

const MAX_RECENT_SEARCHES = 16;

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_FULL_SEARCH_LIST':
      return {
        ...state,
        fullSearchList: action.payload
      };
    case 'FILTER_SEARCH_LIST':
      return {
        ...state,
        searchData: action.payload
      };
    case 'GET_RECENT_SEARCH_LIST':
      return {
        ...state,
        latestStock: action.payload[0],
        recentSearchList: action.payload
      };
    case 'FINIHSED_FETCH_RECENT':
      return {
        ...state,
        isFetchingRecentSearchList: false,
      };
    case 'ADD_TO_RECENT_SEARCH_LIST':
      // Check if the payload already exists in the recentSearchList
      const newRecentSearchList = [...state.recentSearchList];
      if (newRecentSearchList.indexOf(action.payload) !== -1) {
        // Remove the original item from the array
        newRecentSearchList.splice(newRecentSearchList.indexOf(action.payload), 1);
      }
      // Add the payload to the beginning of the array
      newRecentSearchList.unshift(action.payload);
      // Remove the oldest search from the array if the length exceeds MAX_RECENT_SEARCHES
      if (newRecentSearchList.length > MAX_RECENT_SEARCHES) {
        newRecentSearchList.pop();
      }
      return {
        ...state,
        latestStock: newRecentSearchList[0],
        recentSearchList: newRecentSearchList
      };
    case 'COMPARE_SEARCH':
      return {
        ...state,
        compareSearch: action.payload,
      };
    default:
      return state;
  }
};

export default searchReducer;