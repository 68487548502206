// External imports
import React, { useEffect, useState, useMemo } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { Bar, Line } from 'react-chartjs-2'
import { Chart, registerables } from 'chart.js'
import { useTheme } from 'styled-components'

// Internal imports
import { getFormattedValue } from '../../utils/formatUtils'
import {
  fundamentalGraphHelper,
  isOptionShouldNotHide,
  graphOptionHelper,
} from '../../utils/fundamentalUtils'
import { useDispatch, useSelector } from 'react-redux'
import { setUserGraphPreferences } from '../../actions/userPreferences'
import dayjs from 'dayjs'

// Register chart.js plugins
Chart.register(...registerables)

/**
 * Handle when a legend (graph option like revenue / R&D) is clicked
 * @param {UserGraphPreferences} userGraphPreferences
 * @param {Dispatch} displatch
 * @param {string} statType
 * @returns a function
 */

const SymbolButton = styled.button`
  ${tw`flex flex-row items-center text-xs text-textMuted hover:text-textBase`}
  ${(props) => !props.isVisible && 'text-decoration: line-through;'}
`

const handleLegendClick =
  (userGraphPreferences, dispatch, statType) => (e, legendItem, legend) => {
    const index = legendItem.datasetIndex
    const ci = legend.chart
    let bool
    if (ci.isDatasetVisible(index)) {
      ci.hide(index)
      legendItem.hidden = true
      bool = false
    } else {
      ci.show(index)
      legendItem.hidden = false
      bool = true
    }
    dispatch(
      setUserGraphPreferences(
        userGraphPreferences,
        statType,
        graphOptionHelper(legendItem.text, statType),
        bool
      )
    )
  }

/**
 * Graph tooltip formatter
 * @param {object} tooltipItems
 * @returns formatted tooltip title
 */

const tooltipTitle = (tooltipItems) => {
  return dayjs(tooltipItems[0].dataset.date[tooltipItems[0].dataIndex]).format(
    'DD MMM YYYY'
  )
}

// Custom plugin for background dots
const backgroundDotsPlugin = {
  id: 'backgroundDots',
  beforeDraw: (chart) => {
    const { ctx, chartArea: { left, right, top, bottom } } = chart;
    ctx.save();
    ctx.fillStyle = 'rgba(128, 128, 128, 0.3)' //palette.colors.chartDots

    const dotSpacing = 5;
    const dotRadius = 0.5;

    const dotCanvas = document.createElement('canvas');
    dotCanvas.width = dotSpacing;
    dotCanvas.height = dotSpacing;
    const dotCtx = dotCanvas.getContext('2d');

    dotCtx.beginPath();
    dotCtx.arc(dotSpacing / 2, dotSpacing / 2, dotRadius, 0, 2 * Math.PI);
    dotCtx.fillStyle = ctx.fillStyle;
    dotCtx.fill();

    const pattern = ctx.createPattern(dotCanvas, 'repeat');
    ctx.fillStyle = pattern;

    ctx.fillRect(left, top, right - left, bottom - top);
    ctx.restore();
  }
};

export const Graph = ({
  statType,
  chartData,
  comparativeData,
  chartType,
  chartColours,
  isStacked,
  valueFormat,
  displayLegend,
  tickRounding,
  dataRounding,
  symbol,
  compareSymbol,
  differentAxes,
  axis,
}) => {
  const { userGraphPreferences } = useSelector(
    (state) => state.userPreferenceReducer
  )

  const dispatch = useDispatch()
  const palette = useTheme()

  // State for dataset visibility
  const [isMainVisible, setIsMainVisible] = useState(true);
  const [isCompareVisible, setIsCompareVisible] = useState(true);

  // Calculate max value based on visible datasets
  const visibleMaxValue = useMemo(() => {
    return Math.max(...chartData.flatMap((data, index) => 
      isOptionShouldNotHide(userGraphPreferences, statType, data.name) ? data.data : []
    ));
  }, [chartData, userGraphPreferences, statType]);

  const options = {
    maintainAspectRatio: false,
    width: 700,
    height: 450,
    plugins: {
      legend: {
        display: displayLegend,
        labels: {
          usePointStyle: true,
          pointStyle: 'rectRounded',
          color: palette.colors.text,
          filter: (legendItem, data) => legendItem.text !== '',
        },
        onClick: handleLegendClick(
          userGraphPreferences,
          dispatch,
          fundamentalGraphHelper(statType)
        ),
        onHover: (event) => {
          event.native.target.style.cursor = 'pointer'
        },
        onLeave: (event) => {
          event.native.target.style.cursor = 'default'
        },
      },
      tooltip: {
        callbacks: {
          title: tooltipTitle,
        },
      },
      // chartColour: palette.colors.text,
    },
    scales: {
      x: {
        stacked: isStacked,
        grid: {
          display: false,
          drawBorder: false,
          drawOnChartArea: false,
        },
        ticks: {
          display: true,
          color: palette.colors.textMuted,
          autoSkip: false,
          callback: function (value) {
            const labels = this.getLabels()
            const len = labels.length
            if (len > 10) {
              if (len % 2 === 0)
                return value % 2 === 1
                  ? dayjs(this.getLabelForValue(value)).format("MMM 'YY")
                  : ''
              else
                return value % 2 === 0
                  ? dayjs(this.getLabelForValue(value)).format("MMM 'YY")
                  : ''
            }
            return dayjs(this.getLabelForValue(value)).format("MMM 'YY")
          },
        },
      },
      y: {
        stacked: isStacked,
        beginAtZero: true,
        grid: {
          display: false,
          color: palette.colors.borderSecondary,
          drawBorder: false,
          drawOnChartArea: false,
        },
        ticks: {
          display: true,
          color: palette.colors.textMuted,
          callback: function (value, index, values) {
            const decimals = tickRounding
            return getFormattedValue({ value, valueFormat, maxValue: visibleMaxValue, decimals })
          },
        },
      },
      ...(axis && comparativeData && {
        y2: {
          stacked: isStacked,
          beginAtZero: true,
          position: 'right',
          grid: {
            display: false,
            color: palette.colors.borderSecondary,
            drawBorder: false,
            drawOnChartArea: false,
          },
          ticks: {
            display: true,
            color: palette.colors.textMuted,
            callback: function (value, index, values) {
              const decimals = tickRounding
              return getFormattedValue({ value, valueFormat, maxValue: visibleMaxValue, decimals })
            },
          },
        }
      })
    },
    indexAxis: 'x', // Ensures bars are vertical
    categoryPercentage: 0.95, // Adjusts the space between the bars
  }

  const datasets = chartData.map((data, index) => ({
    hidden: !isOptionShouldNotHide(userGraphPreferences, statType, data.name),
    label: data.name,
    data: data.data,
    backgroundColor: chartColours[index],
    borderColor: chartColours[index],
    borderWidth: chartType === 'line' ? 2 : 0,
    stack: data.stack, // Use the stack property if defined
    borderRadius: function (context) {
      const borderWidth = chartData.length >= 3 ? 2 : 3;
      return borderWidth;
    },
    pointRadius: 2,
    cubicInterpolationMode: 'monotone', //smoother lines
    date: data.labels,
    yAxisID: 'y',
    tooltip: {
      callbacks: {
        label: function (context) {
          const value = context.parsed.y;
          const decimals = dataRounding;
          return getFormattedValue({ value, valueFormat, maxValue: visibleMaxValue, decimals });
        },
      },
    },
  }));

  // Add comparative datasets if they exist
  if (comparativeData) {
    comparativeData.forEach((compData, index) => {
      datasets.push({
        hidden: !isOptionShouldNotHide(userGraphPreferences, statType, compData.name),
        label: '',
        data: compData.data,
        backgroundColor: chartColours[index] + '30', // Semi-transparent background
        borderColor: chartColours[index + chartData.length],
        borderWidth: chartType === 'line' ? 2 : 2,
        borderDash: [5, 5], // Dashed border with [dash length, gap length]
        stack: compData.stack, // Use the stack property if defined
        pointRadius: 2,
        cubicInterpolationMode: 'monotone',
        date: compData.labels,
        yAxisID: axis ? 'y2' : 'y',
        tooltip: {
          callbacks: {
            label: function (context) {
              const value = context.parsed.y;
              const decimals = dataRounding;
              return getFormattedValue({ value, valueFormat, maxValue: visibleMaxValue, decimals });
            },
          },
        },
      });
    });
  }
  
  const data = {
    labels: chartData[0].labels.map((date) => dayjs(date).format('MMM YYYY')),
    datasets: datasets,
    statType,
  };

  const SymbolToggle = ({ symbol, isVisible, onClick }) => (
    <SymbolButton isVisible={isVisible} onClick={onClick}>
      <img
        src={`https://financialmodelingprep.com/image-stock/${symbol}.png`}
        width="15"
        height="15"
        alt="stock logo"
        className="my-2 mr-2"
        style={{
          filter: 'drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.4))',
          borderRadius: '3px',
        }}
      />
      {symbol}
    </SymbolButton>
  );

  return chartData && chartData[0]?.labels ? (
    <div className={compareSymbol ? 'h-[calc(100%_-_22px)]' : 'h-full'}>

      {chartType === 'bar' ? (
        <Bar data={data} options={options} plugins={[backgroundDotsPlugin]} />
      ) : (
        <Line data={data} options={options} plugins={[backgroundDotsPlugin]} />
      )}

      {/* Controls for showing/hiding main and comparative symbols */}
      {compareSymbol && ( 
        <div className='w-full h-[10px] flex items-center justify-center space-x-6 pt-4'>
          <SymbolToggle symbol={symbol} isVisible={isMainVisible} onClick={() => setIsMainVisible(!isMainVisible)}/>
          <SymbolToggle symbol={compareSymbol} isVisible={isCompareVisible} onClick={() => setIsCompareVisible(!isCompareVisible)}/>
        </div>
      )}
    </div>
  ) : null;
};