// External imports
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
// import { BsToggles } from "react-icons/bs";
// import { HiOutlineSquaresPlus } from "react-icons/hi2";
import { MdOutlineFitScreen } from 'react-icons/md'
import { useMediaQuery } from 'react-responsive'
import { motion } from 'framer-motion';

// Internal imports
import { Graph, Modal, IconButtons, ContentContainer, NumericOptionSelector, TextOptionSelector, SingleSelectDropdown, BoxAnimation } from '../../components/index'
import { updateFundamentalTimeHorizon, updateFundamentalPeriodType } from '../../actions/userPreferences'
import { useGetFundamentalQuery } from '../../rtk'
import { useDispatch } from 'react-redux'

// Styling
const motionConfig = (delay = 0) => ({
  initial: { opacity: 0, y: 15 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.8, delay },
});

const MovingComponent = (props) => (
  <motion.div {...motionConfig(props.delay)} {...props}/>
);

const CardContainer = styled.div`
  ${tw`w-full grid items-center justify-center bg-primaryBg text-textBase gap-2 rounded`};
  @media (min-width: 1800px) {
    ${tw`grid-cols-4`};
  }
  @media (max-width: 1799px) {
    ${tw`grid-cols-3`};
  }
  @media (max-width: 1399px) {
    ${tw`grid-cols-2`};
  }
  @media (max-width: 899px) {
    ${tw`grid-cols-1`};
  }
`
const Card = styled.div`${tw`w-[fit] h-[fit] text-xl font-semibold p-2 flex flex-col flex-1 justify-start items-center bg-primaryBg`};`
const ContainerTopRow = styled.div`${tw`w-full flex flex-row items-center justify-between font-semibold text-base border-b border-neutral pb-2`};`
const ChartContainer = styled.div`${tw`pt-2 w-full h-[250px]`};`

// Section for displaying the fundamentals of a stock
export const Fundamentals = ({ symbol, compareSymbol, fundamentalTimeHorizon, fundamentalPeriodType }) => {
  const dispatch = useDispatch()

  const { data: fundamentalData, isError, isFetching } = useGetFundamentalQuery({ symbol, period: fundamentalPeriodType })

  const { data: compareFundamentalData, isError: isCompareError, isFetching: isCompareFetching } = useGetFundamentalQuery(
    { symbol: compareSymbol, period: fundamentalPeriodType }, { skip: !compareSymbol }
  );

  const showDropDownToggles = useMediaQuery({ maxWidth: '850px' })

  const getChartDataAndLabels = (key, innerKey) => {
    if (isFetching || isError) return { data: [], labels: []};

    const mainData = [];
    const labels = [];

    const values = fundamentalData[key] || [];

    values.forEach((val, index) => {
      mainData.push(val[innerKey]);
      labels.push(val.date);
    });

    return {
      data: mainData.slice(0, fundamentalTimeHorizon).reverse(),
      labels: labels.slice(0, fundamentalTimeHorizon).reverse(),
    };
  };

  const getCompareChartDataAndLabels = (key, innerKey) => {
    if (isFetching || isError) return { labels: [], compareData: [] };

    const compareData = [];
    const labels = [];

    const values = compareFundamentalData[key] || [];

    values.forEach((val, index) => {
      compareData.push(val[innerKey]);
      labels.push(val.date);
    });

    return {
      data: compareData.slice(0, fundamentalTimeHorizon).reverse(),
      labels: labels.slice(0, fundamentalTimeHorizon).reverse(),
    };
  };

  // Content for all fundamental graphs
  const graphContent = [
    {
      statType: 'Revenue',
      chartData: [
        { name: 'Revenue', ...getChartDataAndLabels('incomeStatement', 'revenue') },
      ],
      comparativeData: compareSymbol != null && !isCompareFetching ? [
        { name: 'Revenue', ...getCompareChartDataAndLabels('incomeStatement', 'revenue') }
      ] : null,
      chartType: 'bar',
      chartColours: ['#84dcc6', '#84dcc680'],
      isStacked: false,
      valueFormat: 'currency',
      tickRounding: 1,
      dataRounding: 2,
      axis: true,
    },
    {
      statType: 'Expenses',
      chartData: [
        { name: 'Cost of Revenue', ...getChartDataAndLabels('incomeStatement', 'costOfRevenue') },
        { name: 'R&D', ...getChartDataAndLabels('incomeStatement', 'researchExpenses') },
        { name: 'Admin', ...getChartDataAndLabels('incomeStatement', 'generalAdmin') },
        { name: 'Marketing', ...getChartDataAndLabels('incomeStatement', 'marketing') },
      ],
      chartType: 'bar',
      chartColours: ['#AD2831', '#FF6961', '#FFC0CB', '#FFA07A'],
      isStacked: true,
      valueFormat: 'currency',
      tickRounding: 1,
      dataRounding: 2,
      axis: true,
    },
    {
      statType: `Earnings`,
      chartData: [
        { name: 'Revenue', ...getChartDataAndLabels('incomeStatement', 'revenue') },
        { name: 'Gross Profit', ...getChartDataAndLabels('incomeStatement', 'grossProfit') },
        { name: 'EBITDA', ...getChartDataAndLabels('incomeStatement', 'ebitda') },
      ],
      comparativeData: compareSymbol != null && !isCompareFetching ? [
        { name: 'Revenue', ...getCompareChartDataAndLabels('incomeStatement', 'revenue') },
        { name: 'Gross Profit', ...getCompareChartDataAndLabels('incomeStatement', 'grossProfit') },
        { name: 'EBITDA', ...getCompareChartDataAndLabels('incomeStatement', 'ebitda') },
      ] : null,
      chartType: 'bar',
      chartColours: ['#84dcc6', '#6ec2b8', '#5f9ea0', '#84dcc680', '#6ec2b880', '#5f9ea080'],
      isStacked: false,
      valueFormat: 'currency',
      tickRounding: 1,
      dataRounding: 2,
      axis: true,
    },
    {
      statType: 'Free Cash Flow',
      chartData: [
        { name: 'FCF', ...getChartDataAndLabels('cashFlow', 'freeCashFlow') },
        { name: 'Stock Based Comp', ...getChartDataAndLabels('cashFlow', 'stockBasedCompensation') },
        { name: 'SBC adj FCF', ...getChartDataAndLabels('cashFlow', 'stockBasedCompensationAdjustedFreeCashFlow') },
      ],
      comparativeData: compareSymbol != null && !isCompareFetching ? [
        { name: 'FCF', ...getCompareChartDataAndLabels('cashFlow', 'freeCashFlow') },
        { name: 'Stock Based Comp', ...getCompareChartDataAndLabels('cashFlow', 'stockBasedCompensation') },
        { name: 'SBC adj FCF', ...getCompareChartDataAndLabels('cashFlow', 'stockBasedCompensationAdjustedFreeCashFlow') },
      ] : null,
      chartType: 'bar',
      chartColours: ['#a7cbd9', '#fa898b', '#809bce', '#a7cbd980', '#fa898b80', '#809bce80',],
      isStacked: false,
      valueFormat: 'currency',
      tickRounding: 1,
      dataRounding: 2,
      axis: true,
    },
    {
      statType: 'Cash & Debt',
      chartData: [
        { name: 'Cash',  ...getChartDataAndLabels('balanceSheet', 'cash') },
        { name: 'Long Term Debt', ...getChartDataAndLabels('balanceSheet', 'debt'), stack: 'Debt' },
        { name: 'Capital Lease Obligations', ...getChartDataAndLabels('balanceSheet', 'capitalLeaseObligations'), stack: 'Debt' },
      ],
      // comparativeData: compareSymbol != null && !isCompareFetching ? [
      //   { name: 'Cash',  ...getCompareChartDataAndLabels('balanceSheet', 'cash') },
      //   { name: 'Long Term Debt', ...getCompareChartDataAndLabels('balanceSheet', 'debt'), stack: 'Debt' },
      //   { name: 'Capital Lease Obligations', ...getCompareChartDataAndLabels('balanceSheet', 'capitalLeaseObligations'), stack: 'Debt' },
      // ] : null,
      chartType: 'bar',
      chartColours: ['#cdeac0', '#fa898b', '#ffbf87', '#cdeac080', '#fa898b80', '#ffbf8780'],
      isStacked: true,
      valueFormat: 'currency',
      tickRounding: 1,
      dataRounding: 2,
      axis: true,
    },
    {
      statType: 'Shares Outstanding',
      chartData: [
        { name: 'Shares Outstanding', ...getChartDataAndLabels('incomeStatement', 'sharesOutstanding') },
      ],
      comparativeData: compareSymbol != null && !isCompareFetching ? [
        { name: 'Shares Outstanding', ...getCompareChartDataAndLabels('incomeStatement', 'sharesOutstanding') },
      ] : null,
      chartType: 'bar',
      chartColours: ['#be9ddf', '#be9ddf80'],
      isStacked: false,
      valueFormat: '',
      tickRounding: 1,
      dataRounding: 2,
      axis: true,
    },
    {
      statType: 'Dividends',
      chartData: [
        { name: 'Dividends', ...getChartDataAndLabels('historicDailyDividend', 'dividend') },
      ],
      comparativeData: compareSymbol != null && !isCompareFetching ? [
        { name: 'Dividends', ...getCompareChartDataAndLabels('historicDailyDividend', 'dividend') },
      ] : null,
      chartType: 'bar',
      chartColours: ['#aebdce', '#aebdce80'],
      isStacked: false,
      valueFormat: 'currency',
      tickRounding: 2,
      dataRounding: 2,
      axis: true,
    },
    {
      statType: 'Effectiveness Ratios',
      chartData: [
        { name: 'ROIC', ...getChartDataAndLabels('keyMetrics', 'roic') },
        { name: 'ROCE', ...getChartDataAndLabels('financialRatios', 'roce') },
        { name: 'ROA', ...getChartDataAndLabels('financialRatios', 'roa') },
        { name: 'ROE', ...getChartDataAndLabels('keyMetrics', 'roe') },
      ],
      comparativeData: compareSymbol != null && !isCompareFetching ? [
        { name: 'ROIC', ...getCompareChartDataAndLabels('keyMetrics', 'roic') },
        { name: 'ROCE', ...getCompareChartDataAndLabels('financialRatios', 'roce') },
        { name: 'ROA', ...getCompareChartDataAndLabels('financialRatios', 'roa') },
        { name: 'ROE', ...getCompareChartDataAndLabels('keyMetrics', 'roe') },
      ] : null,
      chartType: 'line',
      chartColours: ['#809bce', '#EC8F6A', '#cdeac0', '#be9ddf', '#809bce80', '#EC8F6A80', '#cdeac080', '#be9ddf80'],
      isStacked: false,
      valueFormat: 'percentage',
      tickRounding: 0,
      dataRounding: 2,
    },
    {
      statType: 'Margins',
      chartData: [
        { name: 'Gross Profit', ...getChartDataAndLabels('incomeStatement', 'grossProfitRatio') },
        { name: 'EBITDA', ...getChartDataAndLabels('incomeStatement', 'ebitdaratio') },
        { name: 'Profit', ...getChartDataAndLabels('incomeStatement', 'netIncomeRatio') },
      ],
      comparativeData: compareSymbol != null && !isCompareFetching ? [
        { name: 'Gross Profit', ...getCompareChartDataAndLabels('incomeStatement', 'grossProfitRatio') },
        { name: 'EBITDA', ...getCompareChartDataAndLabels('incomeStatement', 'ebitdaratio') },
        { name: 'Profit', ...getCompareChartDataAndLabels('incomeStatement', 'netIncomeRatio') },
      ] : null,
      chartType: 'line',
      chartColours: ['#6ec2b8', '#5f9ea0', '#486989', '#6ec2b880', '#5f9ea080', '#48698980'],
      isStacked: false,
      valueFormat: 'percentage',
      tickRounding: 0,
      dataRounding: 2,
    },
    {
      statType: 'Growth Ratios',
      chartData: [
        { name: 'Revenue', ...getChartDataAndLabels('financialGrowth', 'revenueGrowth') },
        { name: 'Profit', ...getChartDataAndLabels('financialGrowth', 'netIncomeGrowth') },
        { name: 'FCF', ...getChartDataAndLabels('financialGrowth', 'freeCashFlowGrowth') },
      ],
      comparativeData: compareSymbol != null && !isCompareFetching ? [
        { name: 'Revenue', ...getCompareChartDataAndLabels('financialGrowth', 'revenueGrowth') },
        { name: 'Profit', ...getCompareChartDataAndLabels('financialGrowth', 'netIncomeGrowth') },
        { name: 'FCF', ...getCompareChartDataAndLabels('financialGrowth', 'freeCashFlowGrowth') },
      ] : null,
      chartType: 'line',
      chartColours: ['#84dcc6', '#486989', '#A7CBD9', '#84dcc680', '#48698980', '#A7CBD980'],
      isStacked: false,
      valueFormat: 'percentage',
      tickRounding: 0,
      dataRounding: 2,
    },
    {
      statType: 'Price Ratios',
      chartData: [
        { name: 'P/E', ...getChartDataAndLabels( fundamentalPeriodType === 'TTM' ? 'priceRatio' : 'keyMetrics', 'peRatio' ) },
        { name: 'P/FCF', ...getChartDataAndLabels( fundamentalPeriodType === 'TTM' ? 'priceRatio' : 'keyMetrics', 'pfcfRatio') },
      ],
      comparativeData: compareSymbol != null && !isCompareFetching ? [
        { name: 'P/E', ...getCompareChartDataAndLabels( fundamentalPeriodType === 'TTM' ? 'priceRatio' : 'keyMetrics', 'peRatio' ) },
        { name: 'P/FCF', ...getCompareChartDataAndLabels( fundamentalPeriodType === 'TTM' ? 'priceRatio' : 'keyMetrics', 'pfcfRatio') },
      ] : null,
      chartType: 'line',
      chartColours: ['#FBC687', '#32AFA9', '#FBC68780', '#32AFA980'],
      isStacked: false,
      valueFormat: '',
      tickRounding: 0,
      dataRounding: 2,
    },
    {
      statType: 'Per Share Ratios',
      chartData: [
        { name: 'EPS', ...getChartDataAndLabels( fundamentalPeriodType === 'TTM'  ? 'perShareRatio' : 'incomeStatement', 'eps') },
        { name: 'FCFPS', ...getChartDataAndLabels( fundamentalPeriodType === 'TTM' ? 'perShareRatio' : 'keyMetrics', 'freeCashFlowPerShare') },
      ],
      comparativeData: compareSymbol != null && !isCompareFetching ? [
        { name: 'EPS', ...getCompareChartDataAndLabels( fundamentalPeriodType === 'TTM'  ? 'perShareRatio' : 'incomeStatement', 'eps') },
        { name: 'FCFPS', ...getCompareChartDataAndLabels( fundamentalPeriodType === 'TTM' ? 'perShareRatio' : 'keyMetrics', 'freeCashFlowPerShare') },
      ] : null,
      chartType: 'line',
      chartColours: ['#D45D79', '#8AC6D1', '#D45D7980', '#8AC6D180'],
      isStacked: false,
      valueFormat: 'currency',
      tickRounding: 2,
      dataRounding: 2,
      axis: true,
    },
  ]

  return (
    <ContentContainer
      Header={'Financials'}
      hasExpand={true}
      maxWidth={'2500px'}
      Toggle={
        !showDropDownToggles && (
          <TextOptionSelector
            options={['TTM', 'Annual', 'Quarterly']}
            selection={fundamentalPeriodType}
            setSelection={updateFundamentalPeriodType}
          />
        )
      }
      Icons={
        <div className="flex flex-row space-x-2 items-center">
          {!showDropDownToggles && (
            <NumericOptionSelector
              name={ fundamentalPeriodType === 'Quarterly' ? 'Quarters' : 'Years' }
              options={[5, 10, 20, 40]}
              selection={fundamentalTimeHorizon}
              setSelection={updateFundamentalTimeHorizon}
            />
          )}
          {showDropDownToggles && (
            <div className="flex flex-row space-x-2 w-[200px]">
              <SingleSelectDropdown
                setSelected={(option) =>
                  dispatch(updateFundamentalPeriodType(option))
                }
                dropDownOptions={['TTM', 'Annual', 'Quarterly']}
                width={'full'}
                useParentInput={true}
                parentInput={fundamentalPeriodType}
              />
              <SingleSelectDropdown
                setSelected={(option) => dispatch(updateFundamentalTimeHorizon(option))}
                suffix={ fundamentalPeriodType === 'Quarterly' ? 'Quarters' : 'Years' }
                dropDownOptions={[5, 10, 20, 40]}
                width={'fit'}
                useParentInput={true}
                parentInput={fundamentalTimeHorizon}
              />
            </div>
          )}
        </div>
      }
      Content={
        <CardContainer>
          {graphContent.map((card, index) => (
            <MovingComponent key={index} delay={index * 0.1}>
              <StatCard
                key={index}
                {...card}
                isFetchingData={isFetching}
                symbol={symbol}
                compareSymbol={compareSymbol}
              />
            </MovingComponent>
          ))}
        </CardContainer>
      }
    />
  )
}

// Card for each graph
const StatCard = (
  { statType, chartData, comparativeData, chartColours, chartType, isStacked, valueFormat, displayLegend, tickRounding, dataRounding, isFetchingData, symbol, compareSymbol, axis }
) => {
  const [openModal, setOpenModal] = useState(false)
  // const [openSettingsModal, setOpenSettingsModal] = useState(false);
  
  // Disable scrolling when modal is open
  useEffect(() => {
    document.body.style.overflow = openModal ? 'hidden' : 'auto'
  }, [openModal])

  // Individual chart component
 const getChartContent = () => {
    if (!chartData?.[0]?.labels?.length) {
      return (
        <div className="flex justify-center items-center h-full">
          {!isFetchingData ? (
            <h1 className="text-textMuted text-base font-bold">No data to display</h1>
          ) : (
            <BoxAnimation />
          )}
        </div>
      );
    }
    return (
      <Graph
        statType={statType}
        chartData={chartData}
        comparativeData={comparativeData}
        chartType={chartType}
        chartColours={chartColours}
        isStacked={isStacked}
        valueFormat={valueFormat}
        displayLegend={displayLegend}
        tickRounding={tickRounding}
        dataRounding={dataRounding}
        symbol={symbol}
        compareSymbol={compareSymbol}
        axis={axis}
      />
    );
  };

  const Chart = ({ width, height }) => (
    <ChartContainer style={{ width, height }} className="cursor-auto">
      {getChartContent()}
    </ChartContainer>
  );

  return (
    <>
      <Card className="border-1 border-neutral">
        <ContainerTopRow>
          {statType}
          <div className="flex flex-row space-x-2">
            <IconButtons
              action={() => setOpenModal(true)}
              icon={<MdOutlineFitScreen />}
              text="Expand"
            />
          </div>
        </ContainerTopRow>
        <ChartContainer style={{ width: '98%', height: 235 }} className="cursor-auto">
          {getChartContent()}
        </ChartContainer>
      </Card>
      <Modal
        open={openModal}
        closeModal={() => setOpenModal(false)}
        headerContent={<div className="text-lg flex items-center">{symbol} - {statType}</div>}
        content={<Chart height="min(60vw, calc(45vh - 60px))" />}
        footerContent="InvestmentHorizon.io"
        maxWidth="1500px"
        maxHeight="50vh"
      />
    </>
  );
};