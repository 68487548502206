import confetti from 'canvas-confetti';

// const colors = ['#bb0000', '#292cce', '#398045'];
// const colors = ['#18929b', '#f1f094' ];
const colors = ['#bb0000', '#292cce'];
var scalar = 1;
var diamond = confetti.shapeFromText({ text: '💎', scalar });
var coin = confetti.shapeFromText({ text: '🪙', scalar });

const randomInRange = (min, max) => {
  return Math.random() * (max - min) + min;
}

export const ConfettiAnimation = () => {

  const end = Date.now() + (5 * 1000);

  const frame = () => {
    confetti({
      particleCount: 5,
      startVelocity: 40,
      gravity: 2,
      decay:0.9,
      spread: 85,
      angle: 270,
      origin: {
        x: randomInRange(0, 1),
        y: 0
      },
      colors: colors,
      // shapes: [diamond, coin],
      shapes: ['square', 'circle'],
      // flat:true,
      drift: randomInRange(-1, 1)
    });

    if (Date.now() < end) {
      requestAnimationFrame(frame);
    }
  };

  frame();
};