// External imports
import React, { useState, useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import styled, { ThemeProvider } from 'styled-components'
import tw from 'twin.macro'
import { useDispatch, useSelector } from 'react-redux'

// Internal imports
import { UserProfile, PersonalInfo, PaymentsAndPackages, Notifications } from './pages'
import { LandingPage, About, Support, Terms, PrivacyPolicy } from './pages'
import { 
  // Portfolio, 
  StockAnalysis, WatchList, Screener, Calendar } from './pages'
import { MobileAccountMenu } from './pages'
import { lightTheme, darkTheme } from './data/theme'
import { getWatchList, fetchWatchList } from './actions/watchList'
import { getScreenerOptions } from './actions/screener'
import { getTableMetrics, getTableRows, getUserPreferences, getUserGraphPreferences } from './actions/userPreferences'
import ProtectedRoute from './utils/protectedRoute'
import { getToken, getSubscriptionDetails } from './actions/user'
import { getRecentSearchList, getFullSearchList } from './actions/searchLists'
import { Navbar, Sidebar, BoxAnimation } from './components'

//Auth0
import { useAuth0 } from '@auth0/auth0-react'
import { getCustomerCards, getSubscriptionPrices } from './actions/payment'
import SignupTerms from './pages/signup_terms'
import AfterSignup from './pages/after_sign_up'
import { AccountTermsOfService } from './pages/accountManagement/accountTermsOfService'
import { AccountPrivacy } from './pages/accountManagement/accountPrivacy'
import { AccountSupport } from './pages/accountManagement/accountSupport'

const Body = styled.div`${tw`relative w-full min-h-screen select-none flex flex-col items-center justify-start overflow-x-hidden bg-primaryBg`}`
const Content = styled.div`${tw`w-full flex justify-center overflow-y-hidden overflow-x-auto`}`

const App = () => {
  const dispatch = useDispatch()
  const { user, isAuthenticated, isLoading } = useAuth0()
  const { hasLoggedOut } = useSelector((state) => state.userPreferenceReducer)
  const { subscriptionStatus, customerCards } =
    useSelector((state) => state.watchListReducer)
  const stripeSubscriptionId = localStorage.getItem('stripeSubscriptionId')
  const [loading, setLoading] = useState(true)

  const func = async () => {
    await dispatch(getToken(user.email))
    await Promise.all([
      dispatch(getUserPreferences()),
      dispatch(fetchWatchList()),
      dispatch(getWatchList()),
      dispatch(getScreenerOptions()),
      dispatch(getSubscriptionDetails()),
      dispatch(getRecentSearchList()),
      dispatch(getFullSearchList()),
      dispatch(getCustomerCards()),
      dispatch(getTableMetrics()),
      dispatch(getTableRows()),
      dispatch(getSubscriptionPrices()),
      dispatch(getUserGraphPreferences()),
    ])
  }

  useEffect(() => {
    if (!isLoading) {
      if (user?.email_verified) {
        setLoading(true)
        func().finally(() => setLoading(false))
      } else {
        setLoading(false)
      }
    }
  }, [dispatch, isAuthenticated, isLoading])

  const hideNavBar = () => {
    if (
      !hasLoggedOut &&
      user &&
      user.email_verified &&
      isAuthenticated &&
      user.email_verified
    ) {
      if (!stripeSubscriptionId) {
        return true
      } else if (subscriptionStatus === 'trialing') {
        if (!customerCards.id) return true
      } else if (subscriptionStatus !== 'active') {
        return true
      }
      return false
    }
    return true
  }

  return (
    <ThemeProvider
      theme={
        localStorage.getItem('theme') === 'dark' &&
        user?.email_verified &&
        !hasLoggedOut ? darkTheme : lightTheme
      }
    >
      {loading ? (
        <div className="w-screen h-screen flex justify-center items-center">
          <BoxAnimation/>
        </div>
      ) : (
        <div className="flex flex-row-reverse">
          <Body
            className={
              localStorage.getItem('theme') === 'dark' &&
              user?.email_verified &&
              !hasLoggedOut ? 'theme-dark' : 'theme-light'
            }
          >
            {/* Show header nav bar when:
             * user is logged out
             * user is logged in and in a non-desktop screen size 
             * */}
            <div
              className={`flex flex-row w-[100%] ${user ? 'lg:hidden' : ''}`}
            >
              {!hideNavBar() && <Navbar />}
            </div>

            <Content>
              <Routes>
                <Route path="/about" exact element={<About />} />
                <Route path="/support" exact element={<Support />} />
                <Route path="/terms" exact element={<Terms />} />
                <Route path="/privacy" exact element={<PrivacyPolicy />} />
                <Route path="/signup-terms" exact element={<SignupTerms />} />
                <Route path="/afterSignup" exact element={<AfterSignup />} />

                {/* Only accessible when logged out */}
                <Route
                  path="/"
                  exact
                  element={
                    <ProtectedRoute
                      component={<LandingPage />}
                      label={'LandingPage'}
                    />
                  }
                />
                <Route
                  path="/*"
                  exact
                  element={
                    <ProtectedRoute
                      component={<LandingPage />}
                      label={'LandingPage'}
                    />
                  }
                />

                {/* Guarded Routes */}
                <Route
                  path="/stockanalysis"
                  exact
                  element={
                    <ProtectedRoute
                      component={<StockAnalysis />}
                      label={'StockAnalysis'}
                    />
                  }
                />
                {/* <Route
                  path="/portfolio"
                  exact
                  element={
                    <ProtectedRoute
                      component={<Portfolio />}
                      label={'Portfolio'}
                    />
                  }
                /> */}
                <Route
                  path="/stockanalysis/:stock"
                  exact
                  element={
                    <ProtectedRoute
                      component={<StockAnalysis />}
                      label={'StockAnalysis'}
                    />
                  }
                />
                <Route
                  path="/watchList"
                  exact
                  element={
                    <ProtectedRoute
                      component={<WatchList />}
                      label={'WatchList'}
                    />
                  }
                />
                <Route path='/screener' exact element={<ProtectedRoute component={<Screener/>} label={'Screener'} />} />
                <Route path='/calendar' exact element={<ProtectedRoute component={<Calendar/>} label={'Calendar'} />} />
                <Route
                  path="/account"
                  exact
                  element={
                    <ProtectedRoute
                      component={<UserProfile />}
                      label={'UserProfile'}
                    />
                  }
                />
                <Route
                  path="/menu"
                  exact
                  element={
                    <ProtectedRoute
                      component={<MobileAccountMenu />}
                      label={'Menu'}
                    />
                  }
                />
                <Route
                  path="/account/personal-info"
                  exact
                  element={
                    <ProtectedRoute
                      component={<PersonalInfo />}
                      label={'PersonalInfo'}
                    />
                  }
                />
                <Route
                  path="/account/payments-and-packages"
                  exact
                  element={
                    <ProtectedRoute
                      component={<PaymentsAndPackages />}
                      label={'PaymentsAndPackages'}
                    />
                  }
                />
                {/* <Route path='/account/notifications' exact element={<ProtectedRoute component={<Notifications/>}/>} label={'Notifications'} /> */}
                <Route
                  path="/account/terms-of-service"
                  exact
                  element={
                    <ProtectedRoute
                      component={<AccountTermsOfService />}
                      label={'TermsOfService'}
                    />
                  }
                />
                <Route
                  path="/account/privacy"
                  exact
                  element={
                    <ProtectedRoute
                      component={<AccountPrivacy />}
                      label={'Privacy'}
                    />
                  }
                />
                <Route
                  path="/account/support"
                  exact
                  element={
                    <ProtectedRoute
                      component={<AccountSupport />}
                      label={'Support'}
                    />
                  }
                />
              </Routes>
            </Content>
          </Body>
          {!hideNavBar() && (
            <div
              className={`${
                localStorage.getItem('theme') === 'dark' ? 'theme-dark' : 'theme-light'
              } hidden lg:flex h-screen sticky top-0 z-20`}
            >
              <Sidebar />
            </div>
          )}
        </div>
      )}
    </ThemeProvider>
  )
}

export default App