// External imports
import React, { useState, useEffect, createElement } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'
import { BsPerson, BsCreditCard2Front } from 'react-icons/bs'
import { MdOutlineClose } from 'react-icons/md'
import { useAuth0 } from '@auth0/auth0-react'
import { loadStripe } from '@stripe/stripe-js'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import { useMediaQuery } from 'react-responsive'
import { getSubscriptionDetails } from '../../actions/user'

// Internal imports
import {
  ContentContainer,
  Modal,
  SettingsCards,
  FormButton,
  IconButtons,
  // PaymentPlans,
  // StripeContainer,
} from '../../components'
import {
  UserCards,
  SettingsSingleRowContainer,
  SettingsDataContainer,
  PlanCard,
} from '../../components'
import { deleteUser, setSubscriptionType } from '../../actions/user'
import { cancelSubscription, getCustomerCards } from '../../actions/payment'
import { AmexLogo, MasterCardLogo, VisaLogo } from '../../assets'
import { profileSettings } from '../../data/profile'
import { deviceSize } from '../../data/devices'
import dayjs from 'dayjs'

// Styling
export const Container = styled.div`
  ${tw`w-full flex flex-col items-center m-6`};
`
const VerticalContainer = styled.div`
  ${tw`w-full flex flex-col text-sm divide-y divide-neutral space-y-2`};
`
const LogoImg = styled.div`
  ${tw`flex items-center justify-center`};
  width: ${({ width }) => (width ? width : '80px')};
  height: ${({ height }) => (height ? height : '80px')};
  img {
    width: 100%;
    height: 100%;
  }
`
export const PathHeader = ({ icon, pageName }) => (
  <>
    <div>{icon}</div>
    <div className="ml-2">{pageName}</div>
  </>
)

export const UserProfile = () => {
  const isTablet = useMediaQuery({ maxWidth: deviceSize.tablet })

  return (
    <Container>
      <div
        className={`grid ${
          isTablet ? 'grid-cols-1' : 'grid-cols-2'
        } gap-4 mb-4`}
      >
        {profileSettings.map((setting) => (
          <SettingsCards
            key={setting.key}
            header={setting.header}
            content={setting.content}
            page={setting.page}
            icon={createElement(setting.icon)}
          />
        ))}
      </div>
    </Container>
  )
}

export const PersonalInfo = () => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isDeleted, setIsDeleted] = useState(false)
  const { user, isAuthenticated, logout } = useAuth0()
  const dispatch = useDispatch()

  const deleteAccount = () => {
    setIsDeleting(true)
    dispatch(cancelSubscription()).then((res, error) => {
      if (res) {
        console.log('Subscription cancelled successfully.')
        dispatch(deleteUser()).then((res) => {
          if (res) {
            console.log('User deleted successfully.')
            setIsDeleted(true)
            setTimeout(function () {
              doLogout()
            }, 5000)
          }
        })
      } else {
        console.log('Cancel subscription failed.')
      }
      setIsDeleting(false)
    })
  }

  const doLogout = () => {
    localStorage.removeItem('token')
    logout({ logoutParams: { returnTo: window.location.origin } })
  }

  return (
    <Container>
      <ContentContainer
        Header={<PathHeader icon={<BsPerson />} pageName={'Personal info'} />}
        Icons={
          <Link to="/account">
            <IconButtons icon={<MdOutlineClose />} />
          </Link>
        }
        maxWidth={'800px'}
        Content={
          <VerticalContainer>
            {isAuthenticated && (
              <SettingsSingleRowContainer
                header={'Email'}
                option={<div className="text-textMuted">{user.email}</div>}
              />
            )}
            <SettingsSingleRowContainer
              header={'Delete account'}
              topPadding={true}
              option={
                <button
                  className="text-textNegative font-semibold hover:underline"
                  onClick={() => setOpenDeleteModal(true)}
                >
                  Delete
                </button>
              }
            />
            <Modal
              open={openDeleteModal}
              closeModal={() => {
                setOpenDeleteModal(false)
                setIsDeleting(false)
              }}
              headerContent={'Delete Account'}
              content={
                <>
                  {!isDeleted && (
                    <div className="flex flex-col items-center justify-center pb-5">
                      <div className="px-2 py-4 text-center mb-5">
                        <p className="font-semibold text-textNegative">
                          Are you sure you want to delete your account?
                        </p>
                        <span className="flex justify-center">
                          <p className="mt-3 w-96">
                            This action cannot be undone. Deleting your account
                            will cancel your subscription and clear all of your
                            personal data.
                          </p>
                        </span>
                      </div>
                      <FormButton
                        text="Delete"
                        isNegative={true}
                        isLoading={isDeleting}
                        onClick={() => deleteAccount()}
                        width="150px"
                      />
                    </div>
                  )}
                  {isDeleted && (
                    <div className="flex flex-col items-center justify-center pb-5">
                      <div className="px-2 py-4 text-center mb-5">
                        <p className="font-semibold text-[#3498b8]">
                          Account successfully deleted.
                        </p>
                        <span className="flex justify-center">
                          <p className="mt-3 w-96">
                            Redirecting you to our landing page.
                          </p>
                        </span>
                      </div>
                      <FormButton isLoading={true} />
                    </div>
                  )}
                </>
              }
              width={'fit-content'}
            />
          </VerticalContainer>
        }
      />
    </Container>
  )
}

const stripePromise = loadStripe(
  `${process.env.REACT_APP_STRIPE_PUBLISHABLEKEY}`
)

export const PaymentsAndPackages = () => {
  const { logout } = useAuth0()
  const [openUserCards, setOpenUserCards] = useState(false)
  const [openPlanCards, setOpenPlanCards] = useState(false)
  const {
    // stripeIntent,
    // subscriptionType,
    // billingPeriod,
    hasSubscription,
    subscriptionStatus,
    subscriptionDetails,
    customerCards,
  } = useSelector((state) => state.watchListReducer)

  // const [clientSecret, setClientSecret] = useState('')
  const [cards, setCards] = useState([])
  const [remainingDays, setRemainingDays] = useState(0)
  // const [subId, setSubId] = useState()
  // const [userSubscriptionDetails, setUserSubscriptionDetails] = useState([])
  const doLogout = () => {
    dispatch({ type: 'SET_LOGOUT', payload: true })
    localStorage.removeItem('token')
    logout({ logoutParams: { returnTo: window.location.origin } })
  }

  const dispatch = useDispatch()
  // const appearance = { theme: 'stripe' }
  // let options = { clientSecret, appearance }

  // useEffect(() => {
  //   setClientSecret(stripeIntent)
  // }, [stripeIntent])

  useEffect(() => {
    if (customerCards) {
      const logos = {
        visa: VisaLogo,
        mastercard: MasterCardLogo,
        amex: AmexLogo,
      }
      const addLogo = (network) => logos[network] || ''
      const withLogo = {
        id: customerCards.id,
        logo: addLogo(customerCards.brand),
        ...customerCards,
      }
      setCards([withLogo])
    }
  }, [customerCards])

  // const goToPayment = (intent) => {
  //   setClientSecret(intent.setupIntent)
  //   setSubId(intent.subscriptionId)
  //   setUserSubscriptionDetails(intent)
  // }

  // const finishPayment = (isSuccessful) => {
  //   if (isSuccessful) {
  //     saveStripeDetails({
  //       subscriptionType: userSubscriptionDetails.type,
  //       subscriptionId: userSubscriptionDetails.subscriptionId,
  //       billingPeriod: userSubscriptionDetails.billingPeriod,
  //       customerId: userSubscriptionDetails.customerId,
  //       setupIntent: userSubscriptionDetails.setupIntent,
  //     })
  //   }
  // }

  // const saveStripeDetails = (stripeDetails) => {
  //   dispatch(setSubscriptionType(stripeDetails)).then((res) => {
  //     dispatch(getCustomerCards())
  //   })
  // }

  // Fetch subscription details when component loads
  useEffect(() => {
    dispatch(getSubscriptionDetails())
  }, [dispatch])

  useEffect(() => {
    if (subscriptionDetails && subscriptionDetails.status === 'trialing') {
      const trialEnd = dayjs(subscriptionDetails.trial_end * 1000)
      const now = dayjs()
      const days = trialEnd.diff(now, 'days')
      setRemainingDays(days)
    } else if (subscriptionDetails && subscriptionDetails.status === 'active') {
      const trialEnd = dayjs(subscriptionDetails.current_period_end * 1000)
      const now = dayjs()
      const days = trialEnd.diff(now, 'days')
      setRemainingDays(days)
    }
  }, [subscriptionDetails])

  const formatSubscriptionPillMsg = () => {
    if (hasSubscription) {
      if (subscriptionStatus === 'trialing')
        return `Trial ends in ${remainingDays} day${
          remainingDays > 1 ? 's' : ''
        }`
      else if (subscriptionDetails.cancel_at_period_end) {
        return `Subscription ends in ${remainingDays} day${
          remainingDays > 1 ? 's' : ''
        }`
      }
      return subscriptionStatus
    }
    return ''
  }

  const handleToggle = (setToggle, otherToggle) => {
    setToggle((prev) => !prev)
    otherToggle(false)
  }

  const cardExists = !_.isEmpty(cards) && cards[0].id

  const cardInfo = (
    <div className="flex flex-row w-[100%] items-center">
      <div className="mr-2">
        <LogoImg width="30px" height="max-content">
          <img src={cards[0]?.logo} alt="Logo" type="image/png" />
        </LogoImg>
      </div>
      <div>
        <span className="text-md flex items-center">
          <span className="font-semibold mr-1">{cards[0]?.brand}</span>
          <span className="mr-2">•••• {cards[0]?.last4}</span>
          <span className="text-textMuted">
            Expiry: {cards[0]?.exp_month}/
            {cards[0]?.exp_year?.toString().substr(2)}
          </span>
        </span>
      </div>
    </div>
  )

  const userCardsFooter = (
    <UserCards
      stripePromise={stripePromise}
      paymentCompleted={(isSuccessful) => setOpenUserCards(!isSuccessful)}
      updateCompleted={() => setOpenUserCards(false)}
      cards={cards}
      primaryBtnText={cardExists ? 'Update' : 'Activate'}
      secondaryBtnText={cardExists ? 'Cancel' : 'Logout'}
      secondaryCallback={cardExists ? undefined : doLogout}
    />
  )

  return (
    <Container>
      <ContentContainer
        Header={
          <PathHeader
            icon={<BsCreditCard2Front />}
            pageName={'Payments and packages'}
          />
        }
        Icons={
          subscriptionDetails?.default_payment_method ? (
            <Link to="/account">
              <IconButtons icon={<MdOutlineClose />} />
            </Link>
          ) : null
        }
        maxWidth={'600px'}
        Content={
          <VerticalContainer>
            <SettingsDataContainer
              header={'Subscription'}
              info={
                hasSubscription ? (
                  <div className="text-md">
                    Pro - Monthly
                    {/* {subscriptionType === ''
                      ? 'Start a subscription'
                      : `${_.capitalize('pro')} - ${_.capitalize(
                          billingPeriod
                        )}`} */}
                  </div>
                ) : null
              }
              option={
                hasSubscription ? (openPlanCards ? 'Hide' : 'View') : null
              }
              pillMsg={formatSubscriptionPillMsg()}
              onClick={() => handleToggle(setOpenPlanCards, setOpenUserCards)}
              footer={
                hasSubscription ? (
                  openPlanCards ? (
                    <PlanCard subscriptionDetails={subscriptionDetails}  />
                  ) : (
                    <></>
                  )
                ) : (
                  <PlanCard />
                )
              }
            />
            <SettingsDataContainer
              header="Card details"
              info={cardExists && cardInfo}
              option={
                cardExists ? (openUserCards ? 'Hide' : 'Edit') : undefined
              }
              topPadding={true}
              onClick={() => handleToggle(setOpenUserCards, setOpenPlanCards)}
              footer={openUserCards || !cardExists ? userCardsFooter : null}
            />
          </VerticalContainer>
        }
      />
    </Container>
  )
}