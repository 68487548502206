// External imports
import React, { useState } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { useMediaQuery } from 'react-responsive'

// Internal imports
import { Avatar } from '@mui/material'
import { deviceSize } from '../../data/devices'
import { nameConversions } from '../../data/metricOptions'

// Styling
const Logo = styled.div`
  ${tw` w-[30px] h-[30px] flex items-center justify-center`};
  img {
    width: auto;
    max-width: 35px;
    max-height: 35px;
  }
`

export const Company = ({ logo, ticker, name, exchange, onClick }) => {
  const isMobile = useMediaQuery({ maxWidth: deviceSize.mobile })
  const [loadImgErr, setLoadImgErr] = useState(false)

  const onImgErr = () => {
    setLoadImgErr(true)
  }

  const selectedCountry = nameConversions.find((country) =>
    country.exchanges?.includes(exchange)
  )

  return (
    <div
      className={`w-full min-h-[40px] flex items-center px-4 py-3 text-xs ${onClick ? 'hover:bg-neutralBg cursor-pointer' : ''}`}
      onClick={onClick}
      
    >
      <div className="w-full flex items-center justify-start">
        <Logo>
          <img
            src={logo}
            alt=""
            style={{
              maxWidth: '30px',
              maxHeight: '30px',
              filter: 'drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.4))',
              borderRadius: '3px',
              display: loadImgErr ? 'none' : 'block',
            }}
            type="image/png"
            onError={onImgErr}
          />

          {loadImgErr && (
            <Avatar sx={{ bgcolor: '#5f8799', color: '#FFFFFF' }}>
              {name[0]?.toLocaleUpperCase()}
            </Avatar>
          )}
        </Logo>
        <h3 className="w-fit pl-6 text-textMuted flex whitespace-nowrap overflow-hidden text-ellipsis">
          {name} ·
        </h3>
        <div className="w-fit font-bold pl-1 whitespace-nowrap">{ticker}</div>
      </div>
      <div className="w-full flex items-center justify-end">
        {!isMobile && (
          <div className="flex flex-row text-textMuted items-center">
            {exchange}
            <img
              className="ml-2.5"
              src={`https://flagcdn.com/24x18/${selectedCountry?.shortName?.toLowerCase()}.png`}
              width="24"
              height="18"
              alt="Flag"
              type="image/png"
            />
          </div>
        )}
      </div>
    </div>
  )
}